import { IMainNavigationItem } from './main-navigation-items';
import { ITenantAddons } from '../../tenant-addons';
import { ResourceAction } from '../../../authentication/authorization-service';
import { AllowedActions } from '../../../authentication/allowed-actions';

export function getFilteredItems(
  mainNavigationItems: IMainNavigationItem[],
  tenantAddons: ITenantAddons,
  isFeatureEnabled: <T extends string = string>(featureName: T) => boolean,
  allowedActions: AllowedActions
) {
  let filteredItems = [...mainNavigationItems].filter((item: IMainNavigationItem) => {
    if (!checkUserHasAccess(item.resourceActions, allowedActions)) {
      return false;
    }
    if (item.children) {
      const visibleChildrens = item.children.some(x => checkUserHasAccess(x.resourceActions, allowedActions) && checkIsTenantAddonEnabled(tenantAddons, x.tenantAddons));
      if (!visibleChildrens) {
        return false;
      }
    }
    if (item.featureToggleRequired) {
      const featureTogglingEnabled = isFeatureEnabled(item.featureToggleRequired);
      if (!featureTogglingEnabled) {
        return false;
      }
    }
    return true;
  });

  filteredItems = filteredItems.map(x => ({
    ...x,
    children: [
      ...(x.children || []).filter(
        c =>
          checkUserHasAccess(c.resourceActions, allowedActions) &&
          checkIsTenantAddonEnabled(tenantAddons, c.tenantAddons) &&
          checkIsFefeatureToggleRequired(isFeatureEnabled, c.featureToggleRequired)
      ),
    ],
  }));
  return filteredItems;
}

function checkUserHasAccess(actions: ResourceAction[] | undefined, allowedActions: AllowedActions) {
  if (actions) {
    return allowedActions.isAnyAllowed(actions);
  }

  return true;
}

function checkIsTenantAddonEnabled(tenantAddons: ITenantAddons, addonNames?: string[]) {
  if (tenantAddons && addonNames) {
    return tenantAddons.isTenantAddonEnabled(addonNames);
  }
  return true;
}

function checkIsFefeatureToggleRequired(isFeatureEnabled: <T extends string = string>(featureName: T) => boolean, featureToggleRequired: string | undefined) {
  if (featureToggleRequired) {
    const featureTogglingEnabled = isFeatureEnabled(featureToggleRequired);
    if (!featureTogglingEnabled) {
      return false;
    }
  }

  return true;
}
