import React, { useState } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faLink } from '@fortawesome/pro-light-svg-icons';
import { FormDialog } from '../form-dialog';

export interface IChatInfoModalProps {
  text: string;
  url: string;
  title: string;
}

export function ChatInfoModal(props: IChatInfoModalProps) {
  const { text, url, title } = props;

  const [isOpen, setIsOpen] = useState(false);

  function cleanText(textInput: string) {
    // Replace tabs with single spaces
    let temp = textInput.replace(/\t+/g, ' ');

    // Replace multiple spaces with a single space
    temp = temp.replace(/\s{2,}/g, '\n\n');

    // Replace multiple consecutive newlines with a single newline
    temp = temp.replace(/(\n\s*){2,}/g, '\n');
    temp = temp.replace(/(^|\n)(\d)/g, '\n\n$2');

    return temp.trim();
  }

  const Title = (
    <Box display="flex" alignItems="center" gap={1}>
      {title}
      <Tooltip title={`Åben den fulde version af '${title}' `}>
        <IconButton size="small" onClick={() => setIsOpen(true)} title="Vis indhold">
          <FontAwesomeIcon icon={faLink} size="xs" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <div>
      <Box display="flex" alignItems="center" gap={0}>
        <Tooltip title={`Åben den fulde version af '${title}' `}>
          <a href={url} target="_blank" rel="noreferrer">
            {title}
          </a>
        </Tooltip>
        <Tooltip title="Vis detaljer">
          <IconButton size="small" onClick={() => setIsOpen(true)}>
            <FontAwesomeIcon icon={faCircleInfo} size="xs" />
          </IconButton>
        </Tooltip>
      </Box>

      <FormDialog
        open={isOpen}
        title={Title}
        confirmButtonText="Luk"
        cancelButtonText="Luk"
        maxWidth="xl"
        onConfirmClick={() => setIsOpen(false)}
        sx={{
          '& .MuiDialogTitle-root': {
            py: 2,
          },
          '& .MuiDialogContent-root': {
            maxHeight: 'calc(100vh - 197px)',
            overflow: 'auto',
          },
          '& .MuiDialogActions-root': {
            pt: 2,
          },
        }}
      >
        <Box>
          <Box minWidth={800}>
            <Typography style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>{cleanText(text)}</Typography>
          </Box>
        </Box>
      </FormDialog>
    </div>
  );
}
