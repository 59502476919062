import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NavLinkProps } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { WithCrmHostUrlContext } from '../../resource-redirect/crm-host-url-context';
import { moduleClientFactory } from '../../../api-service/module-client-factory';
import { mainNavigationItems, IMainNavigationItem } from './main-navigation-items';
import { Divider } from '../../divider';
import { useFeatureToggles } from '../../../feature-toggles';
import { useAllowedActions } from '../../../authentication';
import { getFilteredItems } from './get-filtered-items';
import { MainNavigationSubMenu } from './main-navigation-sub-menu';
import { MainNavigationNavMenu } from './main-navigation-nav-menu';

import { useTenantAddons } from '../../tenant-addons/tenant-addons-provider';

const useStyles = makeStyles({
  divider: {
    marginTop: 10,
    marginRight: '-8px',
    marginBottom: 10,
    marginLeft: '-8px',
  },
});
export function MainNavigation() {
  const classes = useStyles({});
  const loc = useLocation();
  const { isFeatureEnabled } = useFeatureToggles();
  const tenantAddons = useTenantAddons();

  const [anchorEl, setAnchorEl] = useState<{ anchor?: EventTarget & HTMLButtonElement; guid: string }>();

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, guid: string) {
    if (anchorEl?.anchor !== event.currentTarget) {
      setAnchorEl({ ...anchorEl, anchor: event.currentTarget, guid });
    }
  }

  function handleClose() {
    setAnchorEl({ ...anchorEl, anchor: undefined, guid: '' });
  }

  const isActive = (itemUrl: string, location: NavLinkProps['location'] = loc) => {
    const { pathname } = location;

    if (pathname.indexOf(`/${itemUrl}`) === 0) return true;
    // Custom rule for "citizen" and "citizens" routes
    if (pathname.indexOf('/citizen') === 0 && itemUrl.indexOf('citizen') === 0) return true;

    if (itemUrl === pathname.split('/')[1] || itemUrl === pathname) return true;
    return false;
  };

  const allowedActions = useAllowedActions();

  const filteredItems = getFilteredItems(mainNavigationItems, tenantAddons, isFeatureEnabled, allowedActions);

  return (
    <nav className="layout__main-navigation__nav">
      <WithCrmHostUrlContext configurationClient={moduleClientFactory.createConfigurationClient()}>
        {filteredItems.map((item: IMainNavigationItem) => {
          // Temporary open crm button in left menu
          const isCrmLink = item.guid === '950c8062-f130-43d9-9469-ab55eed6f749';
          return (
            <div key={item.guid} style={{ marginTop: isCrmLink ? 'auto' : '' }}>
              {item.split ? <Divider className={classes.divider} /> : null}
              {!item.children || item.children.length === 0 ? (
                <MainNavigationNavMenu item={item} isActive={isActive(item.url, loc)} />
              ) : (
                <MainNavigationSubMenu item={item} isActive={isActive(item.url, loc)} onClick={handleClick} anchorEl={anchorEl} onClose={handleClose} />
              )}
            </div>
          );
        })}
      </WithCrmHostUrlContext>
    </nav>
  );
}
